/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");

body {
  color: #505050;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

strong {
  font-weight: 500;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

a.anchor {
  display: block;
  position: relative;
  top: -120px;
  visibility: hidden;
}

a {
  text-decoration: none;
}

/* NAV */

.nav-tabs {
  border-bottom: none;
}

.nav-tabs a {
  text-decoration: none;
}

.nav-tabs .nav-link {
  border: none; /* remove the "grey" underline from ul */
  background: none;
  color: #000;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: 500;
  margin-bottom: -1px;
  border-bottom: 3px solid transparent;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:active,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none; /* remove the "grey" underline from ul */
  background: transparent;
  border-bottom: 3px solid #00bcd4;
  color: #00bcd4;
}

header .logo {
  height: 36px;
  margin-top: -16px;
}

.dropdown-toggle {
  margin-top: -2px;
  padding-left: 1rem;
  padding-right: 0;
}

@media (max-width: 768px) {
  header .logo {
    margin-top: 0;
  }

  header.px-5 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .nav-link {
    padding-left: 0.45rem !important;
    padding-right: 0.45rem !important;
  }

  .dropdown-toggle {
    padding-left: 0.45rem !important;
    padding-right: 0.45rem !important;
  }
}

.fw-bold {
  font-weight: 600 !important;
}

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: 3rem;
}

.carousel-inner {
  background-image: url("img/carousel-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 2.5rem;
  z-index: 10;
  max-width: 720px;
  margin: 0 auto;
  left: 10%;
  right: 10%;
  top: 30%;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 30rem;
}
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 32rem;
}

.carousel-control-prev, .carousel-control-next {
  width: 10%;
}

/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
/* rtl:begin:ignore */
.marketing .col-lg-4 p {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
/* rtl:end:ignore */

/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 600;
}

@media (max-width: 768px) {
  .featurette-image {
    margin-bottom: 2rem;
  }
}

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 2.25rem;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 2rem;
  }
}

.card .cover {
  width: 100%;
  background-color: #00bcd4;
  padding: 1.2rem 0 0;
  text-align: center;
  background-image: url("img/card-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0.25rem 0.25rem 0 0;
  min-height: 11.5rem;
  /*background: rgb(0, 188, 212);
  background: linear-gradient(
    0deg,
    rgba(0, 94, 106, 1) 0%,
    rgba(0, 188, 212, 1) 100%
  );*/
}

.card .cover .logo {
  margin: 0 auto;
  display: block;
  border-radius: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border: 5px solid #fff;
  height: 72px;
  width: 72px;
}

a .card {
  color: #505050;
  border: 1px solid #dadada;
  border-radius: 10px;
  text-decoration: none !important;
}

a .card:hover {
  -webkit-box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.3) !important;
  -moz-box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.3) !important;
}

.card h3 {
  font-size: 1.2rem;
  line-height: 1.4;
  color: #fff;
  font-weight: 600;
  padding: 0 0.5rem
}

.card a h3 {
  text-decoration: none;
}

.card a h3:hover, .card a h3:focus  {
  text-decoration: underline;
}

@media (min-width: 992px) {
  .card .card-body {
    min-height: 11rem;
  }
}

.card .card-photoby {
  padding: 0 1rem;
}

.card .card-photoby hr {
  margin: 0 0 1rem;
}

.card .card-photoby p.photographer {
  font-size: 0.75rem;
  line-height: 1.4;
}

.card .card-table {
  font-size: 0.80rem;
  line-height: 1.4;
  margin: 0;
}

.card p.date {
  font-size: 0.9rem;
  line-height: 1.4;
  font-weight: 500;
  margin: 0 0 0.3rem;
}

.card p.date a {
  font-weight: bold;
  color: #007787;
}

.card p.date a:hover, .card p.date a:focus {
  color: #00bcd4;
}

.card p.category {
  font-size: 0.75rem;
  line-height: 2;
  margin: 0;
}

.card p.category span {
  font-weight: 600;
  border-radius: 0.3rem;
  color: #fff !important;
  padding: 0.15rem 0.4rem;
  text-transform: uppercase;
  white-space: nowrap;
}

.btn {
  border-radius: 60px !important;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: normal;
}

.btn-primary {
  background-color: #005e6a;
  border-color: #005e6a;
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: #00bcd4;
  border-color: #00bcd4;
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.25rem rgb(0, 188, 212, 0.5) !important;
  -webkit-box-shadow: 0 0 0 0.25rem rgb(0, 188, 212, 0.5) !important;
  -moz-box-shadow: 0 0 0 0.25rem rgb(0, 188, 212, 0.5) !important;
}

.btn-focus {
  background-color: #ff8f00;
  border-color: #ff8f00;
  color: #fff;
}


.btn-text {
  border-radius: none;
  background: none;
  border: none;
  text-transform: none;
  padding: 0 5px;
  margin-top: -6px;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Roboto' sans-serif;
}

.btn-text:hover,
.btn-text:focus {
  color: #00bcd4;
}

.btn-focus:hover,
.btn-focus:focus {
  background-color: #fbbf09;
  border-color: #fbbf09;
  color: #fff;
}

.btn-focus:focus {
  box-shadow: 0 0 0 0.25rem rgb(255, 193, 7, 0.5) !important;
  -webkit-box-shadow: 0 0 0 0.25rem rgb(255, 193, 7, 0.5) !important;
  -moz-box-shadow: 0 0 0 0.25rem rgb(255, 193, 7, 0.5) !important;
}

.btn-outline-light:hover,
.btn-outline-light:focus {
  color: #00bcd4;
}

.language {
  height: 1.5rem;
  width: auto;
}

footer {
  background-color: #f8f8fa;
  border-top: 1px solid #dee2e6;
  font-size: 0.9rem;
}

footer a {
  font-weight: 500;
  color: #505050;
}

footer a:hover,
footer a:focus {
  color: #000;
}

.feature-img {
  max-width: 500px;
  max-height: 500px;
}

.bg-hero {
  background-image: url("img/hero-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}

